
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Associate from "../service/Associate";

@Options({
  props: {
    associateDetail: Object,
  },
  watch: {
    associateDetail(obj) {
      if (obj.status == "openAssociate") {
        this.openDialog();
      } else if (obj.status == "updateAssociate") {
        this.editIemDialog(obj.employeeId);
      } else {
        this.dialogTitle = "";
      }
      this.permission = obj.permission;
      this.dialogTitle = obj.title;
    },
  },
  emits: ["updateAssociateStatus"],
})
export default class AssociateDialog extends Vue {
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle;
  private associates;
  private permission = "";
  private managerList;
  private storeList;
  private regionsList;
  private hideDisableFields = false;

  private jobClass = [
    { value: "District Manager" },
    { value: "Manager/Assist" },
    { value: "Production Only" },
    { value: "Customer Service" },
    { value: "HQ" },
    { value: "Universal" },
  ];

  private associateType = [
    { key: "Full Time", value: "0" },
    { key: "Part Time", value: "1" },
  ];

  private associate = {
    id: "",
    associateID: "",
    firstName: "",
    lastName: "",
    email: "",
    hourlyRate: 0,
    mimn: 0,
    micmn: 0,
    mas: 0,
    maa: 0,
    maa12: 0,
    mas12: 0,
    maps12: 0,
    mimn12: 0,
    micmn12: 0,
    address: "",
    city: "",
    zipCode: "",
    companyExt: "",
    jobTitle: "",
    region: {},
    homePhone: "",
    performanceNo: 0,
    hireDate: "",
    jobClass: {},
    homeStore: {},
    store2: {},
    store3: {},
    manager: {},
    associateType: {},
    pin: "",
    description: "",
  };
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.associates = new Associate();
    this.toast = new Toaster();
  }

  mounted()
  {
    this.loadOpenDialogData();
  }

  verifyId() {
    if (this.associate.associateID.trim() != "") {
      this.associates
        .verifyAssociateId(this.associate.associateID)
        .then((res) => {
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
    }
  }

  verifyPin() {
    if (this.associate.pin.trim() != "") {
      this.associates.verifyAssociatePin(this.associate.pin).then((res) => {
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
      });
    }
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.productDialog = true;
    this.dialogTitle = "Add Associate";
    this.loadOpenDialogData();
  }
  
  loadOpenDialogData()
  {
    this.associates.openDialog().then((data) => {
      this.managerList = data.manager_list;
      this.storeList = data.store_list;
      this.regionsList = data.regions;
      this.associate.pin = data.employee_id;
    });
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.associate.firstName.trim()) {
      if (this.associate.id != "") {
        this.associates.updateItem(this.associate).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.associates.saveItem(this.associate).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;

      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIemDialog(id) {
    this.submitted = false;
    this.dialogTitle = "Update Associate";
    this.hideDisableFields = true;

    if (id != "") {
      this.associates.getItem(id).then((res) => {
        if (res) {
          this.managerList = res.manager_list;
          this.storeList = res.store_list;
          this.regionsList = res.regions;

          const region = this.regionFilter(res.single_emp.region_id);

          const manager = this.managerListFilter(res.single_emp.manager);

          const homeStore = this.storeFilter(res.single_emp.branch_id);
          const store2 = this.storeFilter(res.single_emp.branch_id_2);
          const store3 = this.storeFilter(res.single_emp.branch_id_3);

          const jobClass = this.jobClassFilter(res.single_emp.job_class);
          const associateType = this.associateTypeFilter(
            res.single_emp.employee_type
          );

          this.associate.id = res.single_emp.employee_id;
          this.associate.associateID = res.single_emp.employee_id;
          this.associate.firstName = res.single_emp.employee_firstname;
          this.associate.lastName = res.single_emp.employee_name;
          this.associate.email = res.single_emp.employee_email;
          this.associate.hourlyRate = res.single_emp.hourly_rate;
          this.associate.address = res.single_emp.employee_address;
          this.associate.city = res.single_emp.city;
          this.associate.zipCode = res.single_emp.zip;
          this.associate.companyExt = res.single_emp.companyext;
          this.associate.jobTitle = res.single_emp.job_title;

          this.associate.region = region;
          this.associate.homeStore = homeStore;
          this.associate.store2 = store2;
          this.associate.store3 = store3;
          this.associate.jobClass = jobClass;
          this.associate.manager = manager;
          this.associate.associateType = associateType;

          this.associate.hireDate = res.single_emp.hire_date;
          this.associate.homePhone = res.single_emp.user_contact_1;
          this.associate.performanceNo = res.single_emp.performance;
          this.associate.description = res.single_emp.employee_description;
          this.associate.maa = res.single_emp.average_alteration;
          this.associate.mimn = res.single_emp.magic;

          this.productDialog = true;
        }
      });
    } else {
      this.toast.showError("Could not be able to load the record invalid ID");
    }
  }

  // //USED TO FILTER REGIONS
  regionFilter(res) {
    let region = {};
    this.regionsList.filter((elem) => {
      if (elem.region_id == res) {
        region = elem;
      }
    });
    return region;
  }

  //USED TO FILTER MANAGERLIST
  managerListFilter(res) {
    let manager = {};
    this.managerList.filter((elem) => {
      if (elem.employee_id == res) {
        manager = elem;
      }
    });
    return manager;
  }

  //USED TO FILTER STORE
  storeFilter(res) {
    let selectedStore = {};
    this.storeList.filter((elem) => {
      if (elem.code == res) {
        selectedStore = elem;
      }
    });
    return selectedStore;
  }

  //USED TO FILTER JOB CLASS
  jobClassFilter(res) {
    let jobClass = {};
    this.jobClass.filter((elem) => {
      if (elem.value == res) {
        jobClass = elem;
      }
    });
    return jobClass;
  }

  //USED TO FILTER ASSOCIATE TYPE
  associateTypeFilter(res) {
    let associateType = {};
    this.associateType.filter((elem) => {
      if (elem.value == res) {
        associateType = elem;
      }
    });
    return associateType;
  }

  clearItem() {
    this.associate.id = "";
    this.associate.associateID = "";
    this.associate.firstName = "";
    this.associate.lastName = "";
    this.associate.email = "";
    this.associate.hourlyRate = 0;
    this.associate.address = "";
    this.associate.city = "";
    this.associate.zipCode = "";
    this.associate.companyExt = "";
    this.associate.jobTitle = "";

    this.associate.region = {};
    this.associate.homeStore = {};
    this.associate.store2 = {};
    this.associate.store3 = {};
    this.associate.jobClass = {};
    this.associate.manager = {};
    this.associate.associateType = {};

    this.associate.hireDate = "";
    this.associate.homePhone = "";
    this.associate.performanceNo = 0;
    this.associate.description = "";
    this.associate.maa = 0;
    this.associate.mimn = 0;
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    this.clearItem();
    this.$emit("updateAssociateStatus");
  }
}
