
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import { IAnnounce,branchDetail } from "../pages/announcements/IAnnounce";
import Announcement from "../service/Announcement";

import Editor from "primevue/editor";

@Options({
  components: {
    Editor
  },
  props: {
    announceDetail: Object,
  },
  watch: {
    announceDetail(obj) {

      if(obj.status == 'openAnnouncement')
      {
        this.openDialog();
      }
      else if(obj.status == 'updateAnnouncement')
      {
        this.editIem(obj.annoucementId);
      }
      else
      {
        this.dialogTitle = '';
      }
    }
  },
  emits: ["updateAnnounceStatus"],
})
export default class AnnouncementDialog extends Vue {
  private createdStore = '';
  private toast;
  private submitted     = false;
  private productDialog = false;
  private dialogTitle;
  private announcements;
  private myStores: Array<branchDetail> = [];
  private filterTypes = [
    {
      value: "All Branches",
      name: "All Stores",
    },
    {
      value: "Specific Branches",
      name: "Specific Stores",
    },
  ];

  private notifiactionType = [
    {
      value: "Promotional",
      name: "Promotional",
    },
    {
      value: "Informative",
      name: "Informative",
    },
    {
      value: "News",
      name: "News",
    },
    {
      value: "Emergency",
      name: "Emergency",
    },
  ];

  private announce: IAnnounce = {
    annoucementId: "",
    notification: {},
    title: "",
    description: "",
    managerId: "",
    branchId: "",
    annoucementsType: {},
    assignedBranchId: [],
    status: "active",
  };


  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.announcements = new Announcement();
    this.toast = new Toaster();
  }

  mounted()
  {
    this.announcements.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    //this.clearItem();
    this.submitted = false;
    this.productDialog = true;
    this.dialogTitle = "Create Announcement";
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.announce.title.trim()) {
      if (this.announce.annoucementId != "") {
        this.announcements.updateItem(this.announce).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.announcements.saveItem(this.announce).then((res) => {
           this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;

      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(id) {

    this.submitted        = false;
    this.dialogTitle      = "Update Announcement";

    if(id != '')
    {
      this.announcements.getItem(id).then((res) => {

        if (res.single_annouce.length > 0) {

          this.createdStore = res.current_branch_code;

          const notificationType = this.notifiactionFilter(res.single_annouce[0]);

          const announcementType = this.announcementFilter(res.single_annouce[0]);

          const stores = this.storesFilter(res.single_annouce[0]);

          this.announce.annoucementId    = res.single_annouce[0].annoucement_id;
          this.announce.notification     = notificationType;
          this.announce.title            = res.single_annouce[0].title;
          this.announce.description      = res.single_annouce[0].description;
          this.announce.managerId        = '';
          this.announce.branchId         = res.single_annouce[0].branch_id;
          this.announce.annoucementsType = announcementType;
          this.announce.assignedBranchId = stores;
          this.announce.status           = res.single_annouce[0].status;

          this.productDialog    = true;
        }
      });
    }
    else
    {
        this.toast.showError('Could not be able to load the record invalid ID');
    }
  }

  //USED TO FILTER TYPES
  notifiactionFilter(res)
  {
    let type = {};

    this.notifiactionType.filter((elem) => {
        if (elem.value == res.notification)
        {
          type = elem;
        }
      });

      return type;
  }

  //USED TO FILTER TYPES
  announcementFilter(res)
  {
    let type = {};

    this.filterTypes.filter((elem) => {
        if (elem.value == res.annoucements_type)
        {
          type = elem;
        }
      });

      return type;
  }

  //USED TO FILTER TYPES
  storesFilter(res)
  {
    const stores: Array<branchDetail> = [];

    this.myStores.filter((elem) => {
      res.assigned_branch_id.filter((storedElem) => {
        if (elem.code == storedElem) {
            stores.push({
              code: elem.code,
              mall: elem.mall,
            });
        }
      });
    });

      return stores;
  }



  clearItem() {
    this.announce.annoucementId = '';
    this.announce.notification = {};
    this.announce.title = '';
    this.announce.description = '';
    this.announce.managerId = '';
    this.announce.branchId = '';
    this.announce.annoucementsType = {};
    this.announce.annoucementId = '';
    this.announce.assignedBranchId = [];
    this.announce.status = "active";
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    this.clearItem();
    this.$emit("updateAnnounceStatus");
  }
}
