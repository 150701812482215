<template>
  <div class="row bb-set-height">
    <div class="col-md-12 p-p-0">
      <h5 class="mt-0 mb-0 checkout-heading pl-2">
        <i class="pi pi-file-o"></i> Order ID : {{ receiptID }}
        <span class="pull-right">
          Due : {{ formatDate(orderDetail.dueDate) }} | Time :
          {{ formatTime(orderDetail.dueTime) }}
        </span>
      </h5>
      <h5 class="text-center mt-3 mb-3">BuyBack Option</h5>
      <ul class="visitor list-unstyled">
        <li
          class="media pt-3 pb-3 m-0 text-center"
          style="background-color: #f7f7f7"
        >
          <div
            v-if="orderDetail.description != ''"
            class="media-body order-top-header"
          >
            <h6>Memo : {{ orderDetail.description }}</h6>
          </div>
        </li>
        <template v-for="order in orderItems" :key="order">
          <li style="border-bottom:1px dotted #ccc;"
            :class="{ 'express-attribute': order.express == 'express' }"
            class="media p-2 mb-0"
          >
            <img
              v-if="order.image"
              class="mr-2 mt-2 rounded-circle"
              width="50"
              :src="imagePath + order.image"
              alt="avatar"
            />
            <div
              :class="{ 'express-attribute': order.express == 'express' }"
              class="media-body"
            >
              <div class="pt-2">
                <label class="mb-0 service-name">
                  <input
                    style="width: 20px; height: 20px"
                    type="radio"
                    class="radio-style"
                    name="sale_item_id"
                    :disabled="order.checkStatus == 'REFUND'"
                    @click="getBuyBackItem(order.saleItemId)"
                    :value="order.saleItemId"
                  />
                  {{ order.serviceName }} + Tax
                  <b> (${{ fixLength(order.totalBill) }})</b>
                </label>
                <label class="pull-right">
                  <span v-if="order.adminApprove != ''" class="approve-bb">
                    {{ order.adminApprove }}
                  </span>
                  <span v-if="order.checkStatus != ''" class="status-bb mr-4">
                    {{ order.checkStatus }}
                  </span>
                </label>
              </div>
              <div>
                <span style="font-size: 18px">
                  Total Bill + Tax ${{ fixLength(order.totalBill) }}
                </span>
              </div>
              <div v-if="order.description != ''" class="order-top-header">
                Memo: {{ order.description }}
              </div>
              <label v-if="order.express == 'express'">
                <i>
                  Due Date {{ formatDate(order.dueDate) }} | Due Time
                  {{ formatTime(order.dueTime) }}
                </i></label
              >
            </div>
          </li>
        </template>
      </ul>

      <h5 class="mt-0 mb-0 checkout-heading pl-2" id="example-Modal3">
        <i class="pi pi-id-card" aria-hidden="true"></i>
        Buy Back Customer Item
      </h5>
      <div class="col-md-12" id="buyback_content_area">
        <fieldset>
          <legend><i class="pi pi-user"></i> Customer Information</legend>
          <div class="form-group mt-3">
            <label>Payable To</label>

            <input
              type="text"
              class="form-control"
              v-model="buybackItems.customerName"
              :class="{ 'p-invalid': submitted && !buybackItems.customerName }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.customerName">Customer Name is required.</small>
          </div>
          <div class="form-group">
            <label>Address</label>
            <input
              type="text"
              class="form-control"
              placeholder="e.g Customer Address"
              v-model.trim="buybackItems.address"
              :class="{ 'p-invalid': submitted && !buybackItems.address }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.address">Address is required.</small>
          </div>
        </fieldset>
        <fieldset>
          <legend><i class="pi pi-money-bill"></i> Payment Information</legend>
          <div class="form-group mt-5">
            <label>Does a cheque need to be sent to Customer?</label>
            <span class="btn-group align-top pull-right">
              <label class="btn btn-md btn-primary badge">
                <b class="by-opt-radio"> Customer </b>
                <input
                  type="radio"
                  class="radio-style"
                  checked
                  name="cheque_need_customer"
                  v-model.trim="buybackItems.chequeNeedCustomer"
                  value="Customer"
                />
              </label>
              <label class="btn btn-md btn-danger badge">
                <b class="by-opt-radio"> Store </b>
                <input
                  type="radio"
                  name="cheque_need_customer"
                  class="radio-style"
                  v-model.trim="buybackItems.chequeNeedCustomer"
                  value="Store"
                />
              </label>
            </span>
          </div>
          <div class="form-group mt-5 mb-5">
            <label>Did you payout in store (under $50)?</label>
            <span class="btn-group align-top pull-right">
              <label class="btn btn-md btn-primary badge">
                <b class="by-opt-radio"> Yes </b>
                <input
                  type="radio"
                  checked
                  class="radio-style"
                  v-model.trim="buybackItems.payoutInStore"
                  value="Yes"
                />
              </label>
              <label class="btn btn-md btn-danger badge">
                <b class="by-opt-radio"> No </b>
                <input
                  type="radio"
                  class="radio-style"
                  v-model.trim="buybackItems.payoutInStore"
                  value="No"
                />
              </label>
            </span>
          </div>
          <div class="form-group">
            <label class="bb-color-red"
              >Total Cost of Garment (to be found on Customers Receipt) ?</label
            >
            <input
              type="number"
              class="buyback-text-field"
              v-model="buybackItems.totalOfGarments"
              :class="{ 'p-invalid': submitted && !buybackItems.totalOfGarments }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.totalOfGarments">Amount is required.</small>
          </div>

          <div class="form-group mt-5 mb-5">
            <label>Do we need to include refund for alterations on Cheque to Customer ?</label>
            <span class="btn-group align-top pull-right ">
                <label class="btn btn-md btn-primary badge">
                    <b class="by-opt-radio"> Yes </b>
                    <input type="radio" v-model.trim="buybackItems.refundAlterationCus" name="refund_alteration_cus"  class="radio-style"   value="Yes" />
                </label>
                <label class="btn btn-md btn-danger badge">
                    <b class="by-opt-radio"> No </b>
                    <input type="radio" checked  v-model.trim="buybackItems.refundAlterationCus" name="refund_alteration_cus"   class="radio-style" value="No" />
                </label>
            </span>
        </div>

          <div class="form-group">
            <label class="bb-color-red">If yes please fill in $ for alterations: </label>
            <input
              type="number"
              class="buyback-text-field"
              v-model="buybackItems.fillInPrice"
              :class="{ 'p-invalid': submitted && !buybackItems.fillInPrice }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.fillInPrice">Fill in Price is required.</small>
          </div>
          <div class="form-group">
            <h3 class="buyback_total_headings">
              TOTAL $ TO BE PAID TO CUSTOMER:
            </h3>
            <Button label="Calulate" @click="calculateTotalBill" icon='pi pi-calendar' class="p-button-lg p-ml-2 p-button-success pull-right" />
            <input
              type="number"
              class="buyback-text-field"
              readonly
              :value="buybackItems.totalToPaid.toFixed(2)"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.totalToPaid">Total Paid is required.</small>
          </div>
        </fieldset>
        <fieldset>
          <legend><i class="pi pi-pencil"></i> Description & Other</legend>
          <div class="form-group">
            <label> Reason: </label>
            <select
              name="reason"
              v-model.trim="buybackItems.reason"
              class="form-control"
            >
              <option value="Quality">Quality</option>
              <option value="Wrong">Wrong</option>
              <option value="Customer Service">Customer Service</option>
            </select>
            <small class="p-invalid" v-if="submitted && !buybackItems.reason">Reason is required.</small>
          </div>
          <div class="form-group">
            <label> Garment Description: </label>
            <input
              type="text"
              class="form-control"
              name="garment_description"
              v-model.trim="buybackItems.garmentDescription"
              :class="{ 'p-invalid': submitted && !buybackItems.garmentDescription }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.garmentDescription">Description is required.</small>
          </div>
          <div class="form-group mt-5 mb-5">
            <label>Receipt in Mail?</label>
            <span class="btn-group align-top pull-right">
              <label class="btn btn-md btn-primary badge">
                <b class="by-opt-radio"> Yes </b>
                <input
                  type="radio"
                  checked
                  v-model.trim="buybackItems.receiptInMail"
                  class="radio-style"
                  value="Yes"
                />
              </label>
              <label class="btn btn-md btn-danger badge">
                <b class="by-opt-radio"> No </b>
                <input
                  type="radio"
                  v-model.trim="buybackItems.receiptInMail"
                  class="radio-style"
                  value="No"
                />
              </label>
            </span>
          </div>
          <div class="form-group">
            <label>Garment in Mail?</label>
            <span class="btn-group align-top pull-right">
              <label class="btn btn-md btn-primary badge">
                <b class="by-opt-radio"> Yes </b>
                <input
                  type="radio"
                  checked
                  v-model.trim="buybackItems.garmentInMail"
                  class="radio-style"
                  value="Yes"
                />
              </label>
              <label class="btn btn-md btn-danger badge">
                <b class="by-opt-radio"> No </b>
                <input
                  type="radio"
                  v-model.trim="buybackItems.garmentInMail"
                  class="radio-style"
                  value="No"
                />
              </label>
            </span>
          </div>
        </fieldset>
        <fieldset>
          <legend><i class="pi pi-user"></i> Manager & Associate Info</legend>
          <div class="form-group">
            <label class="bb-color-red"> Associate PIN</label>
            <input
              type="password"
              class="form-control"
              required="true"
              v-model.trim="buybackItems.employeeId"
              :class="{ 'p-invalid': submitted && !buybackItems.employeeId }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.employeeId">PIN is required.</small>
          </div>
          <div class="form-group">
            <label class="bb-color-red"> Manager who approved buyback PIN?</label>
            <input
              type="password"
              class="form-control"
              required="true"
              v-model.trim="buybackItems.managerWhoApproved"
              :class="{ 'p-invalid': submitted && !buybackItems.managerWhoApproved }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.managerWhoApproved">PIN is required.</small>
          </div>
          <div class="form-group">
            <label>Fit Expert's name who rang in garment?</label>
            <input
              type="text"
              class="form-control"
              required="true"
              v-model.trim="buybackItems.fitExpertName"
              :class="{ 'p-invalid': submitted && !buybackItems.fitExpertName }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.fitExpertName">Fit in Expert is required.</small>
          </div>
          <div class="form-group">
            <label>Sew Expert's name who altered garment?</label>
            <input
              type="text"
              class="form-control"
              required="true"
              v-model.trim="buybackItems.sewExpertName"
              :class="{ 'p-invalid': submitted && !buybackItems.sewExpertName }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.sewExpertName">Saw Expert is required.</small>
          </div>

          <div class="form-group">
            <label
              >What have you done to address this issue with the associate(s)
              responsible?</label
            >
            <input
              type="text"
              class="form-control"
              required="true"
              v-model.trim="buybackItems.associateResponsible"
              :class="{ 'p-invalid': submitted && !buybackItems.dueTime }"
            />
            <small class="p-invalid" v-if="submitted && !buybackItems.associateResponsible">Field is required.</small>
          </div>
        </fieldset>
         <Button v-if="buybackItems.id == ''" label="Proceed to Next" @click="proceedNext()" icon="pi pi-arrow-right" class="p-button-lg p-button-primary pull-right" />
      </div>
    </div>
  </div>
  <PaymentScreen
  :receiptDetail="{
    dialogStatus: paymentDialog,
    totalBill: buybackItems.totalToPaid,
    itemSource: 'Buyback',
    restriction: 'Yes',
    dialogTilte: dialogTilte,
    customerID: customerID,
    customerName: customerName,
    needlePoints:CustomerNeedles,
    closeConfirmation: false,
    }"
    v-on:closePaymentScreenEvent="closePaymentScreen"
    v-on:getProceededPaymentsEvent="getProceededPayments"/>
</template>

<script lang="ts">
import { Vue,Options } from "vue-class-component";
import moment from "moment";
import { useStore } from "../store";
import BuyBackService from "../service/BuyBackService";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../components/PaymentScreen.vue";

@Options({
  inheritAttrs: false,
  components: {
    PaymentScreen
  },
   emits: ["searchReceiptEvent","updateRedoReceipt","clearCheckOutEvent"],
})

export default class BuyBack extends Vue {
  private dialogTilte = ' Buyback Purchase Payments';
  private txnService;
  private customerID = '';
  private customerName = '';
  private CustomerNeedles = 0;
  private paymentDialog = false;
  private submitted = false;
  private imagePath = "";
  private toast;
  private vuexStore = useStore();
  private orderDetail = {
    dueDate: "",
    dueTime: "",
    description: "",
    cusId: "",
  };

  private orderItems = [
    {
      express: "",
      image: "",
      serviceName: "",
      totalBill: "",
      saleItemId: "",
      adminApprove: "",
      checkStatus: "",
      description: "",
      dueDate: "",
      dueTime: "",
    },
  ];

  private buybackItems =
    {
      id: "",
      salesItemID: "",
      customerName: "",
      address: "",
      chequeNeedCustomer: "Customer",
      payoutInStore: "Yes",
      totalOfGarments: 0,
      refundAlterationCus: "",
      fillInPrice: 0,
      totalToPaid: 0,
      reason: "Quality",
      garmentDescription: "",
      receiptInMail: "Yes",
      garmentInMail: "Yes",
      employeeId: "",
      managerWhoApproved: "",
      fitExpertName: "",
      sewExpertName: "",
      associateResponsible: "",
    };

  get receiptID() {
    return this.vuexStore.getters.getReceiptID;
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new BuyBackService();
    this.toast = new Toaster();
    this.imagePath = this.txnService.getBaseURL() + "uploads/services/";
  }

  mounted() {
    this.getReceiptBB();
  }

  getReceiptBB() {
    this.txnService.getBBDetails(this.receiptID).then((data) => {
      const itemsDetail = this.camelizeKeys(data);
      this.orderDetail = itemsDetail.orderDetails;
      this.orderItems = itemsDetail.orderItems;
      this.customerID = itemsDetail.orderDetails.cusId;
      this.customerName = itemsDetail.orderDetails.customer;
      this.CustomerNeedles = itemsDetail.orderDetails.customerNeedles;
      this.buybackItems.customerName = itemsDetail.orderDetails.customer;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  fixLength(amount) {
    amount = Number(amount);

    if (amount != "") {
      amount = amount.toFixed(2);
    }

    return amount;
  }

  calculateTotalBill()
  {
    let alerationPrice = 0;

    if(this.buybackItems.refundAlterationCus == 'Yes')
    {
      alerationPrice =  Number(this.buybackItems.fillInPrice);
    }
    else
    {
      alerationPrice =  0;
    }

    const totalGarment =  Number(this.buybackItems.totalOfGarments);
    const total = (alerationPrice + totalGarment).toFixed(2);
    this.buybackItems.totalToPaid = Number(total);
  }

  getBuyBackItem(salesItemID)
  {
    this.buybackItems.salesItemID = salesItemID;

    this.txnService.getSalesItemID(salesItemID,this.receiptID).then((data) => {
      const buyBackDetail = this.camelizeKeys(data);
      const bbResult      = buyBackDetail.bbResult[0];
      if(bbResult)
      {
        this.buybackItems.id                  = bbResult.id;
        this.buybackItems.customerName        = buyBackDetail.customerDetails[0].firstName+' '+buyBackDetail.customerDetails[0].lastName;
        this.buybackItems.address             = buyBackDetail.customerDetails[0].address;
        this.buybackItems.chequeNeedCustomer  = bbResult.chequeNeedCustomer;
        this.buybackItems.payoutInStore       = bbResult.payoutInStore;
        this.buybackItems.totalOfGarments     = bbResult.totalOfGarments;
        this.buybackItems.refundAlterationCus = bbResult.refundAlterationCus;
        this.buybackItems.fillInPrice         = bbResult.fillInPrice;
        this.buybackItems.totalToPaid         = bbResult.totalToPaid;
        this.buybackItems.reason              = bbResult.reason;
        this.buybackItems.garmentDescription  = bbResult.garmentDescription;
        this.buybackItems.receiptInMail       = bbResult.receiptInMail;
        this.buybackItems.garmentInMail       = bbResult.garmentInMail;
        //this.buybackItems.employeeId          = bbResult.employeeId;
        //this.buybackItems.managerWhoApproved  = bbResult.managerWhoApproved;
        this.buybackItems.sewExpertName        = buyBackDetail.sawExpertName;
        this.buybackItems.fitExpertName        = buyBackDetail.fitinName;
        this.buybackItems.associateResponsible = bbResult.associateResponsible;
      }
    });
  }

  proceedNext()
  {
    this.submitted = true;

    const validate = this.validateFields();

    if(validate)
    {

    if(this.buybackItems.payoutInStore == 'Yes' && this.buybackItems.totalToPaid > 50)
		{
			this.toast.showWarning('Payout in store is exceeding','You are not allowed to pay in store if buyback amount is greater then $50','warning');
		}

    if(this.buybackItems.totalToPaid <= 0)
    {
      this.toast.showError('Invalid buyback amount. Amount must be greater then 0');
    }
    else if(this.buybackItems.employeeId == '' || this.buybackItems.managerWhoApproved == '')
    {
      this.toast.showError('Please enter pin of associate and manager of store');
    }
    else
    {
      this.verfiyManagerAndAssociatePin()
    }
    }
    else
    {
      this.toast.showError('Please fill all the required fields');
    }
  }

  verfiyManagerAndAssociatePin()
  {
    this.txnService.verifyPINS(this.buybackItems.managerWhoApproved,this.buybackItems.employeeId).then((res) => {
      this.toast.handleResponse(res);

      if(res.alert == 'info')
      {
        //OPEN PAYMENT SCREEN
        this.paymentDialog = true;
      }
    });
  }

  closePaymentScreen()
  {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList)
  {
    this.txnService.sendBBReq(this.buybackItems,paymentList,this.customerID,this.receiptID).then((res) => {

      if(res.alert == 'info')
      {
        this.$emit('clearCheckOutEvent');
      }

      this.toast.handleResponse(res);
    });
  }

  validateFields()
  {
    if(this.buybackItems.fitExpertName.trim() == '' || this.buybackItems.sewExpertName.trim() == '' || this.buybackItems.associateResponsible.trim() == '' || this.buybackItems.customerName.trim() == '' || this.buybackItems.address.trim() == '')
    {
      return false;
    }
    else
    {
      return true;
    }
  }
}
</script>

<style scoped>
.status-bb {
  background-color: #03a900;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  text-transform: uppercase;
}
.approve-bb {
  background-color: #00b100;
  color: #fff;
  padding: 5px;
  margin-right: 5px;
  border-radius: 3px;
  text-transform: uppercase;
}

fieldset {
  border: 1px solid #ddd !important;
  margin: 20px 0px;
  min-width: 0;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  background-color: #f7f7f7;
  padding-left: 10px !important;
}

legend {
  font-size: 14px;
  margin-bottom: 0px;
  width: 50%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
  color: #004c97;
  background-color: #ffffff;
}

.by-opt-radio {
  color: #fff;
  font-size: 18px;
}

.express-attribute {
  background-color: #c00;
  color: #fff !important;
}

.radio-style {
  width: 25px;
  height: 25px;
}

.buyback-text-field {
  height: 60px;
  width: 300px;
  float: right;
  font-size: 60px;
  color: white;
  background-color: #929292;
  border-radius: 5px;
}

.buyback_total_headings {
  color: #c00;
  float: left;
  margin-top: 15px;
  display: block;
  width: 100%;
}

.service-name {
  font-size: 16px;
}

.bb-set-height {
  height: 90.5vh;
  min-height: 90.5vh;
  overflow-y: scroll;
}

.bb-color-red
{
  color:#c00;
}
</style>
