
import { Vue,Options } from "vue-class-component";
import moment from "moment";
import { useStore } from "../store";
import BuyBackService from "../service/BuyBackService";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../components/PaymentScreen.vue";

@Options({
  inheritAttrs: false,
  components: {
    PaymentScreen
  },
   emits: ["searchReceiptEvent","updateRedoReceipt","clearCheckOutEvent"],
})

export default class BuyBack extends Vue {
  private dialogTilte = ' Buyback Purchase Payments';
  private txnService;
  private customerID = '';
  private customerName = '';
  private CustomerNeedles = 0;
  private paymentDialog = false;
  private submitted = false;
  private imagePath = "";
  private toast;
  private vuexStore = useStore();
  private orderDetail = {
    dueDate: "",
    dueTime: "",
    description: "",
    cusId: "",
  };

  private orderItems = [
    {
      express: "",
      image: "",
      serviceName: "",
      totalBill: "",
      saleItemId: "",
      adminApprove: "",
      checkStatus: "",
      description: "",
      dueDate: "",
      dueTime: "",
    },
  ];

  private buybackItems =
    {
      id: "",
      salesItemID: "",
      customerName: "",
      address: "",
      chequeNeedCustomer: "Customer",
      payoutInStore: "Yes",
      totalOfGarments: 0,
      refundAlterationCus: "",
      fillInPrice: 0,
      totalToPaid: 0,
      reason: "Quality",
      garmentDescription: "",
      receiptInMail: "Yes",
      garmentInMail: "Yes",
      employeeId: "",
      managerWhoApproved: "",
      fitExpertName: "",
      sewExpertName: "",
      associateResponsible: "",
    };

  get receiptID() {
    return this.vuexStore.getters.getReceiptID;
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new BuyBackService();
    this.toast = new Toaster();
    this.imagePath = this.txnService.getBaseURL() + "uploads/services/";
  }

  mounted() {
    this.getReceiptBB();
  }

  getReceiptBB() {
    this.txnService.getBBDetails(this.receiptID).then((data) => {
      const itemsDetail = this.camelizeKeys(data);
      this.orderDetail = itemsDetail.orderDetails;
      this.orderItems = itemsDetail.orderItems;
      this.customerID = itemsDetail.orderDetails.cusId;
      this.customerName = itemsDetail.orderDetails.customer;
      this.CustomerNeedles = itemsDetail.orderDetails.customerNeedles;
      this.buybackItems.customerName = itemsDetail.orderDetails.customer;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  fixLength(amount) {
    amount = Number(amount);

    if (amount != "") {
      amount = amount.toFixed(2);
    }

    return amount;
  }

  calculateTotalBill()
  {
    let alerationPrice = 0;

    if(this.buybackItems.refundAlterationCus == 'Yes')
    {
      alerationPrice =  Number(this.buybackItems.fillInPrice);
    }
    else
    {
      alerationPrice =  0;
    }

    const totalGarment =  Number(this.buybackItems.totalOfGarments);
    const total = (alerationPrice + totalGarment).toFixed(2);
    this.buybackItems.totalToPaid = Number(total);
  }

  getBuyBackItem(salesItemID)
  {
    this.buybackItems.salesItemID = salesItemID;

    this.txnService.getSalesItemID(salesItemID,this.receiptID).then((data) => {
      const buyBackDetail = this.camelizeKeys(data);
      const bbResult      = buyBackDetail.bbResult[0];
      if(bbResult)
      {
        this.buybackItems.id                  = bbResult.id;
        this.buybackItems.customerName        = buyBackDetail.customerDetails[0].firstName+' '+buyBackDetail.customerDetails[0].lastName;
        this.buybackItems.address             = buyBackDetail.customerDetails[0].address;
        this.buybackItems.chequeNeedCustomer  = bbResult.chequeNeedCustomer;
        this.buybackItems.payoutInStore       = bbResult.payoutInStore;
        this.buybackItems.totalOfGarments     = bbResult.totalOfGarments;
        this.buybackItems.refundAlterationCus = bbResult.refundAlterationCus;
        this.buybackItems.fillInPrice         = bbResult.fillInPrice;
        this.buybackItems.totalToPaid         = bbResult.totalToPaid;
        this.buybackItems.reason              = bbResult.reason;
        this.buybackItems.garmentDescription  = bbResult.garmentDescription;
        this.buybackItems.receiptInMail       = bbResult.receiptInMail;
        this.buybackItems.garmentInMail       = bbResult.garmentInMail;
        //this.buybackItems.employeeId          = bbResult.employeeId;
        //this.buybackItems.managerWhoApproved  = bbResult.managerWhoApproved;
        this.buybackItems.sewExpertName        = buyBackDetail.sawExpertName;
        this.buybackItems.fitExpertName        = buyBackDetail.fitinName;
        this.buybackItems.associateResponsible = bbResult.associateResponsible;
      }
    });
  }

  proceedNext()
  {
    this.submitted = true;

    const validate = this.validateFields();

    if(validate)
    {

    if(this.buybackItems.payoutInStore == 'Yes' && this.buybackItems.totalToPaid > 50)
		{
			this.toast.showWarning('Payout in store is exceeding','You are not allowed to pay in store if buyback amount is greater then $50','warning');
		}

    if(this.buybackItems.totalToPaid <= 0)
    {
      this.toast.showError('Invalid buyback amount. Amount must be greater then 0');
    }
    else if(this.buybackItems.employeeId == '' || this.buybackItems.managerWhoApproved == '')
    {
      this.toast.showError('Please enter pin of associate and manager of store');
    }
    else
    {
      this.verfiyManagerAndAssociatePin()
    }
    }
    else
    {
      this.toast.showError('Please fill all the required fields');
    }
  }

  verfiyManagerAndAssociatePin()
  {
    this.txnService.verifyPINS(this.buybackItems.managerWhoApproved,this.buybackItems.employeeId).then((res) => {
      this.toast.handleResponse(res);

      if(res.alert == 'info')
      {
        //OPEN PAYMENT SCREEN
        this.paymentDialog = true;
      }
    });
  }

  closePaymentScreen()
  {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList)
  {
    this.txnService.sendBBReq(this.buybackItems,paymentList,this.customerID,this.receiptID).then((res) => {

      if(res.alert == 'info')
      {
        this.$emit('clearCheckOutEvent');
      }

      this.toast.handleResponse(res);
    });
  }

  validateFields()
  {
    if(this.buybackItems.fitExpertName.trim() == '' || this.buybackItems.sewExpertName.trim() == '' || this.buybackItems.associateResponsible.trim() == '' || this.buybackItems.customerName.trim() == '' || this.buybackItems.address.trim() == '')
    {
      return false;
    }
    else
    {
      return true;
    }
  }
}
